import { useCallback, useEffect } from 'react'
import useIndexedDB from '../useIndexedDB'

import appContext from '../../context'
import utils from '../../utils'

const { useAppContext } = appContext

const APP_CONTEXT_MODULE_ID = 'display_history'

export default useMainDisplayModuleHistory

export const SETUP_STATES = {
  UNINITIALIZED: 'SETUP_STATE_UNINITIALIZED',
  INITIALIZED: 'SETUP_STATE_INITIALIZED',
}

export const ACTIONS = {
  INITIALIZE: 'ACTION_INITIALIZE',
}

function useMainDisplayModuleHistory(){
  useIndexedDB()

  const [ app_context, set_app_context ] = useAppContext()

  const plucked_context_map = utils.app_context.pluck({
    app_context,
    keys_to_pluck: [
      'username',
      'indexed_db_client',
      'display_history_module',
      'display_history_initialized',
      'display_history_module_state',
      'display_history_module_action',
    ]
  })

  const {
    username,
    indexed_db_client,
    display_history_module,
    display_history_initialized,
    display_history_module_state,
    display_history_module_action,
  } = plucked_context_map

  // bind functions to state with useCallback
  const async_callback_action_initialize = useCallback( async () => {
    return await async_action_initialize({
      display_history_module,
      display_history_initialized,
      display_history_module_action,
      set_app_context,
      app_context,
      indexed_db_client,
      username,
    })
  }, [
    display_history_module,
    display_history_initialized,
    display_history_module_action,
    set_app_context,
    app_context,
    indexed_db_client,
    username,
  ])

  // setup hook effects
  // ===

  // 1. create data indexes module if it doesn't exist
  useEffect(() => {
    if (display_history_module) return;

    utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    display_history_module,
  ])

  // initialize / setup
  useEffect(() => {
    if (!username || !indexed_db_client || !display_history_module || display_history_initialized || display_history_module_action) return;

    async_callback_action_initialize({
      username,
      indexed_db_client,
      display_history_module,
      display_history_initialized,
      display_history_module_action,
    })
  }, [
    username,
    indexed_db_client,
    display_history_module,
    display_history_initialized,
    display_history_module_action,
    async_callback_action_initialize,
  ])

  return {
    loaded: display_history_initialized,
    get: getModuleHistory,
    set: setModuleHistory,
  }

  function getModuleHistory({ id }) {
    return display_history_module_state?.display_history?.[ id ] ?? null
  }

  async function setModuleHistory({ id, expanded }) {
    const updated_display_history = {
      ...( display_history_module_state?.display_history ?? {} ),
      [ id ]: {
        expanded,
      },
    }

    // save updates to disk
    const display_history_disk_wrapper = {
      username,
      display_history: updated_display_history,
    }

    const save_display_history_wrapper = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'display-history' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const {
      transaction: save_display_history_transaction,
      transaction_completed_promise: save_display_history_transaction_promise,
    } = save_display_history_wrapper

    save_display_history_transaction
      .objectStore( 'display-history' )
      .put( display_history_disk_wrapper, username )

    // save to memory
    utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates: {
        display_history: {
          ...(display_history_module_state?.display_history ?? {}),
          [ id ]: {
            expanded,
          },
        }
      }
    })
  }

  async function async_action_initialize({
    display_history_module,
    display_history_initialized,
    display_history_module_action,
    set_app_context,
    app_context,
    indexed_db_client,
    username,
  }) {
    const error_msg_prefix = `cannot initialize hook "${ APP_CONTEXT_MODULE_ID }"`

    if (!display_history_module) throw new Error( `${ error_msg_prefix } -- module not yet created` )
    if (display_history_initialized) throw new Error( `${ error_msg_prefix } -- already initialized` )
    if (display_history_module_action) throw new Error( `${ error_msg_prefix } -- another action is running` )

    utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates: {
        action: ACTIONS.INITIALIZE,
      }
    })

    const action_log = [
      'action=initialize-module',
      'module=display-history',
    ]

    try {
      action_log.push( 'source=disk' )

      const get_display_history_from_disk = utils.indexed_db.create_transaction({
        client: indexed_db_client,
        databases: [ 'display-history' ],
        permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READ_ONLY,
      })

      const {
        transaction: get_history_transaction,
        transaction_completed_promise: get_history_transaction_promise,
      } = get_display_history_from_disk

      const on_disk_display_history_wrapper = get_history_transaction
        .objectStore( 'display-history' )
        .get( username )

      await get_history_transaction_promise

      if (!on_disk_display_history_wrapper) throw new Error( 'display history for logged-in user not found on disk' )

      const {
        username: on_disk_display_history_owner,
        display_history: on_disk_display_history,
      } = on_disk_display_history_wrapper.result

      if (on_disk_display_history_owner !== username) throw new Error( 'current user is not owner of saved display history' )

      utils.app_context.update_module_state({
        module_id: APP_CONTEXT_MODULE_ID,
        set_app_context,
        app_context,
        state_updates: {
          display_history: on_disk_display_history,
        }
      })

      action_log.push( 'success=true' )
    }

    catch( initialization_error ) {
      action_log.push(
        'success=false',
        `error="${ initialization_error.message }"`,
      )

      // couldn't load history, use empty history instead
      utils.app_context.update_module_state({
        module_id: APP_CONTEXT_MODULE_ID,
        set_app_context,
        app_context,
        state_updates: {
          display_history: {},
        }
      })
    }

    console.log( action_log.join( ' ' ) )

    utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates: {
        initialized: true,
        action: null,
      }
    })
  }
}
