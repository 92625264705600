import appContext from '../../context'
import utils from '../../utils'

import usePocketAuth, { AUTH_STATES, ACTIONS as AUTH_ACTIONS, get_pocket_auth_state } from '../../hooks/usePocketAuth'

import SparkpocketjoyLogo from '../../components/SparkpocketjoyLogo'

import { ReactComponent as SparksIcon } from '../../svgs/sparks-icon.svg'
import { ReactComponent as PocketLogo } from '../../svgs/pocket-logo.svg'

import './style.css'

const { useAppContext } = appContext

const FEATURE_PITCHES = {
  stats_counts: {
    title: 'count how many links you saved',
    subtitle: '(or archived, favorited, tagged etc)',
    image: '/stats-counts.png',
  },
  surf_search: {
    title: 'search saves with multiple criteria',
    subtitle: '(specific tags, has any/no tags, pocket status like queued, archived, favorited and more)',
    image: '/surf-search.png',
  },
  surf_sort: {
    title: 'randomize saves or search results',
    subtitle: '(or sort by word count, date saved)',
    image: '/surf-sort.png',
  },
}

const MainLanding = () => {
  usePocketAuth()

  const [ app_context ] = useAppContext()
  const {
    pocket_auth_user,
    pocket_auth_action,
    pocket_auth_initialized,
    pocket_auth_module_actions,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'pocket_auth_user',
        'pocket_auth_action',
        'pocket_auth_initialized',
        'pocket_auth_module_actions',
      ]
    })

  const { async_log_in } = pocket_auth_module_actions ?? {}

  const pocket_auth_state = get_pocket_auth_state( pocket_auth_user ?? {} )

  const feature_pitch_keys = Object.keys( FEATURE_PITCHES )
  const feature_pitch_cta_map = feature_pitch_keys
    .reduce(( feature_pitch_cta_map, feature_pitch_key ) => {

      switch (feature_pitch_key) {
        case 'stats_counts':
        case 'surf_search':
        case 'surf_sort':
          {
            feature_pitch_cta_map[ feature_pitch_key ] = (
              <>
                <div style={{ color: 'grey', fontSize: '.85em', minWidth: '144px' }}>
                  requirements
                </div>
                <div
                  style={{
                    color: async_log_in ? 'orange' : 'grey',
                    lineHeight: '1.44em',
                    marginTop: '-.4em',
                    ...(
                      async_log_in
                        ? {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        : {}
                    )
                  }}
                  {
                    ...(
                      async_log_in
                        ? { onClick: async_log_in }
                        : {}
                    )
                  }
                >
                  <PocketLogo
                    height='1em'
                    style={{
                      verticalAlign: '-0.24em',
                      marginRight: '0.25em',
                    }}
                  />
                  {
                    pocket_auth_state === AUTH_STATES.UNIDENTIFIED
                    && 'connect to pocket'
                  }
                  {
                    (
                      pocket_auth_state === AUTH_STATES.REQUEST_TOKEN
                      ||  pocket_auth_state === AUTH_STATES.ACCESS_REQUESTED
                    )
                    && 'connecting ...'
                  }
                </div>
              </>
            )
          }
          break

        default:
          throw new Error( `unexpected feature pitch key "${ feature_pitch_key }"` )
      }

      return feature_pitch_cta_map
    }, {})



  return (
    pocket_auth_initialized
      ? (
          <div className='MainLanding'>
            <div
              style={{
                textAlign: 'center',
                marginTop: '4.4em',
                fontSize: '1.44em',
              }}
            >
              <span style={{ display: 'block', marginBottom: '3.3em' }}>
                <SparkpocketjoyLogo />
              </span>
              <div
                style={{
                  fontWeight: 900,
                  fontSize: '1.44em',
                  letterSpacing: '-2px',
                  lineHeight: '1em',
                  textTransform: 'lowercase',
                }}
              >
                SIMPLE TOOLS
              </div>
              <div
                style={{
                  fontWeight: 900,
                  fontSize: '1.44em',
                  letterSpacing: '-2px',
                  lineHeight: '1em',
                  textTransform: 'lowercase',
                }}
              >
                FOR
                <PocketLogo height='1em' style={{ margin: '0 .2em', verticalAlign: '-.25em', padding: '0 .2em' }} />
                POCKET USERS
              </div>
            </div>
            <div>
              <ol
                style={{
                  marginTop: '4.4em',
                  listStyleType: 'none',
                }}
              >
                {
                  Object
                    .keys( FEATURE_PITCHES )
                    .map( feature_pitch_key => {
                      return {
                        key: feature_pitch_key,
                        ... FEATURE_PITCHES[ feature_pitch_key ],
                      }
                    })
                    .map( ({ key, title, subtitle, image }) => {

                      return (
                        <MainLandingListItem
                          key={ `${title}-${subtitle}` }
                          title={ title }
                          subtitle={ subtitle }
                          feature_init_link={
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                                color: 'orange',
                                fontSize: '.75em',
                                marginTop: '3em',
                                fontWeight: 400,
                              }}
                            >
                              { feature_pitch_cta_map[ key ] }
                            </div>
                          }
                          {
                            ...(
                              image
                                ? { image }
                                : {}
                            )
                          }
                        />
                      )
                    })
                }
              </ol>
            </div>
          </div>
        )
      : null
  )
}

export default MainLanding

const MainLandingListItem = ({ title, subtitle, image, feature_init_link }) => {

  return (
    <li
      style={{
        letterSpacing: '-1px',
        marginBottom: '8em',
        fontSize: '1.44em',
        lineHeight: '1em',
        fontWeight: 900,
      }}
    >
      <SparksIcon fill='orange' style={{ height: '1.44em', marginRight: '.2em', verticalAlign: '.55em', float: 'left' }} />
      <span
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          maxWidth: '64%',
          width: '100%',
        }}
      >
        <span>
          { title }
        </span>
        <span
          style={{
            display: 'block',
            fontWeight: 300,
            color: '#aaa',
          }}
        >
          { subtitle }
        </span>
        {
          image &&
          (
            <img
              src={ `${ image }` }
              style={{
                maxWidth: '350px',
                minWidth: '250px',
                marginTop: '2em',
                width: '100%',
              }}
            />
          )
        }
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
          }}
        >
          { feature_init_link }
        </div>
      </span>
    </li>
  )
}