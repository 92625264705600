import appContext from '../../context'
import utils from '../../utils'

import MainDisplayModule from '../../components/MainDisplayModule'
import ExpandedViewItemGroup from '../../components/ExpandedViewItemGroup'
import ExpandedViewItem from '../../components/ExpandedViewItem'
import useDataIndexes from '../../hooks/useDataIndexes'
import useMainDisplayModuleHistory from '../../hooks/useMainDisplayModuleHistory'

import { ReactComponent as DocumentsSVG } from '../../svgs/documents-icon.svg'
import { ReactComponent as BarChartSVG } from '../../svgs/bar-chart-icon.svg'
import { ReactComponent as ArchivesSVG } from '../../svgs/archives-icon.svg'
import { ReactComponent as BookmarkSVG } from '../../svgs/bookmark-icon.svg'
import { ReactComponent as FavoriteSVG } from '../../svgs/favorite-icon.svg'
import { ReactComponent as GlobeSVG } from '../../svgs/globe-icon.svg'
import { ReactComponent as ImageSVG } from '../../svgs/image-icon.svg'
import { ReactComponent as InboxSVG } from '../../svgs/inbox-icon.svg'
import { ReactComponent as VideoSVG } from '../../svgs/video-icon.svg'
import { ReactComponent as WriteSVG } from '../../svgs/write-icon.svg'

import './style.css'

const DISPLAY_MODULE_ID = 'ContentStats'

const { useAppContext } = appContext

const ContentStats = () => {
  useDataIndexes()

  const [ app_context ] = useAppContext()
  const {
    sync_id,
    data_indexes,
    content_data,
    display_history_initialized,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'sync_id',
        'data_indexes',
        'content_data',
        'display_history_initialized',
      ]
    })

  const { get: get_history, set: set_history } = useMainDisplayModuleHistory()

  const display_history = get_history({ id: DISPLAY_MODULE_ID })
  const { expanded: module_expanded } = display_history ?? {}

  const savesCount = data_indexes?.content?.length ?? 0
  const archivedSavesCount = data_indexes?.content_archived?.length ?? 0
  const queuedSavesCount = savesCount - archivedSavesCount

  return (
    (display_history_initialized && sync_id)
      ? <MainDisplayModule
          id="ContentStats"
          label="stats"
          inline={
            (() => {
              return <>
                {
                  !data_indexes && 'loading ...'
                }
                {
                  data_indexes &&
                  `${ savesCount } saves - ${ queuedSavesCount } queued, ${ archivedSavesCount } archived`
                }
              </>
            })()
          }
          expanded={
            <>
              <div className='Title'>
                <span className='Icon'>
                  <BarChartSVG />
                </span>
                { 'pocket statistics' }
              </div>
              {
                data_indexes && (
                  <>
                    <ExpandedViewItemGroup title="about saves">
                      <ExpandedViewItem
                        icon={ <DocumentsSVG /> }
                        label={
                          savesCount === 1
                            ? `1 save`
                            : `${ savesCount } saves`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <InboxSVG /> }
                        label={ `${ data_indexes?.content?.length - data_indexes?.content_archived?.length ?? 0 } queued` }
                      />
                      <ExpandedViewItem
                        icon={ <ArchivesSVG /> }
                        label={ `${ data_indexes?.content_archived?.length ?? 0 } archived` }
                      />
                      <ExpandedViewItem
                        icon={ <FavoriteSVG /> }
                        label={
                          data_indexes?.content_favorited?.length === 1
                            ? '1 favorite'
                            : `${ data_indexes?.content_favorited?.length ?? 0 } favorites`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <BookmarkSVG /> }
                        label={`${ data_indexes?.content_tagged?.length ?? 0 } tagged` }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="about user">
                      <ExpandedViewItem
                        icon={ <BookmarkSVG /> }
                        label={
                          data_indexes?.tags?.length === 1
                            ? '1 tag'
                            : `${ content_data?.user_tags?.length ?? 0 } tags`
                        }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="sources of saves">
                      <ExpandedViewItem
                        icon={ <WriteSVG /> }
                        label={
                          data_indexes?.authors?.length === 1
                            ? '1 author'
                            : `${ data_indexes?.authors?.length ?? 0 } authors`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <GlobeSVG /> }
                        label={
                          data_indexes?.domains?.length === 1
                            ? '1 domain'
                            : `${ data_indexes?.domains?.length ?? 0 } domains`
                        }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="media in saves">
                      <ExpandedViewItem
                        icon={ <ImageSVG /> }
                        label={
                          data_indexes?.images?.length === 1
                            ? '1 image'
                            : `${ data_indexes?.images?.length ?? 0 } images`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <VideoSVG /> }
                        label={
                          data_indexes?.videos?.length === 1
                            ? '1 video'
                            : `${ data_indexes?.videos?.length ?? 0 } videos`
                        }
                      />
                    </ExpandedViewItemGroup>
                  </>
                )
              }
            </>
          }
          initialIsInline={ !module_expanded }
          onDisplayHistoryUpdate={
            ({ expanded: updated_expanded }) => {
              set_history({ id: DISPLAY_MODULE_ID, expanded: updated_expanded })
            }
          }
        />
      : null
  )
}

export default ContentStats
