import ReactTimeAgo from 'react-time-ago'

import MainDisplayModule from '../../components/MainDisplayModule'
import ExpandedViewItemGroup from '../../components/ExpandedViewItemGroup'
import ExpandedViewItem from '../../components/ExpandedViewItem'

import appContext from '../../context'
import utils from '../../utils'
import useUserSyncStatus, { ACTIONS as SYNC_STATUS_ACTIONS } from '../../hooks/useUserSyncStatus'
import useMainDisplayModuleHistory from '../../hooks/useMainDisplayModuleHistory'

import { ReactComponent as SyncSVG } from '../../svgs/sync-icon.svg'
import { ReactComponent as LockSVG } from '../../svgs/lock-icon.svg'
import { ReactComponent as DeleteSVG } from '../../svgs/delete-icon.svg'
import { ReactComponent as HourGlassSVG } from '../../svgs/hourglass-icon.svg'
import { ReactComponent as SyncCalendarSVG } from '../../svgs/sync-calendar-icon.svg'

import './style.css'

const { useAppContext } = appContext

const PocketSyncStatus = () => {
  useUserSyncStatus()

  const [ app_context ] = useAppContext()
  const {
    username,
    access_token,
    next_sync_status,
    current_sync_status,
    sync_status_module_actions,
    sync_status_action,
    sync_status_module_state,
    display_history_initialized,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'username',
        'access_token',
        'next_sync_status',
        'current_sync_status',
        'sync_status_module_actions',
        'sync_status_action',
        'sync_status_module_state',
        'display_history_initialized',
      ]
    })

  const { get: get_history, set: set_history } = useMainDisplayModuleHistory()
  const display_history = get_history({ id: 'PocketSyncStatus' })
  const { expanded: module_expanded } = display_history ?? {}

  const { async_start_sync, async_clear_sync } = sync_status_module_actions ?? {}

  const hasPocketUserCredentials = access_token && username
  const lastSyncDate = current_sync_status?.last_sync && (new Date( current_sync_status.last_sync ))
  const syncQueuedDate = next_sync_status?.queued_time && (new Date( next_sync_status.queued_time ))
  const syncStartedDate = next_sync_status?.start_time && (new Date( next_sync_status.start_time ))

  const now_date = new Date()
  const now_epoch = now_date.getTime()
  const tomorrow_date = new Date( now_epoch )
  tomorrow_date.setDate( now_date.getDate() + 1 )
  tomorrow_date.setHours( 0, 0, 0 )

  const { daily_sync_available } = sync_status_module_state ?? {}

  const sync_state = next_sync_status?.start_time
    ? 'syncing'
    : next_sync_status?.queued_time
      ? 'queued'
      : null

  const sync_history = current_sync_status
    ? 'has-synced'
    : 'never-synced'

  const syncStateId = next_sync_status?.start_time
    ? 'currently-syncing'
    : sync_history

  const enable_sync_button =
    !sync_status_action
    && !next_sync_status?.queued_time

  return (
    hasPocketUserCredentials && display_history_initialized
      ? <MainDisplayModule
          id="PocketSyncStatus"
          label="sync"
          inline={ getInlineContent() }
          expanded={
            <>
              <div className='Title'>
                <span className="Icon">
                  <SyncSVG />
                </span>
                <span className="Label">
                  sync with pocket
                </span>
              </div>
              <ExpandedViewItemGroup title="history">
                <ExpandedViewItem
                  icon={ <SyncCalendarSVG /> }
                  label={
                    <>
                      {
                        sync_history === 'has-synced'
                          ? <>
                              { 'last synced ' }
                              <ReactTimeAgo date={ lastSyncDate } />
                            </>
                          : 'never synced before'
                      }
                    </>
                  }
                />
              </ExpandedViewItemGroup>
              {
                next_sync_status
                && (
                  <ExpandedViewItemGroup title="about sync">
                    <ExpandedViewItem
                      icon={ <HourGlassSVG /> }
                      label={
                        <>
                          {
                            next_sync_status.status === 'queued'
                              ? 'queued'
                              : 'started'
                          }
                          { ' ' }
                          <ReactTimeAgo date={ sync_state === 'queued' ? syncQueuedDate : syncStartedDate } timeStyle='round' />
                        </>
                      }
                    />
                    <ExpandedViewItem
                      icon={ <SyncSVG /> }
                      label={
                        next_sync_status.hasOwnProperty( 'total_synced' )
                          ? `synced ${ next_sync_status.total_synced } updates so far`
                          : 'waiting for updates'
                      }
                    />
                  </ExpandedViewItemGroup>
                )
              }
              {
                (
                  !next_sync_status
                  || current_sync_status === 'completed'
                )
                && (
                  <ExpandedViewItemGroup title="actions">
                    {
                      !enable_sync_button
                      && (
                        <ExpandedViewItem
                          className={ 'StartSync disabled' }
                          icon={ <SyncSVG fill='currentColor' /> }
                          label={ 'starting sync ...' }
                        />
                      )
                    }
                    {
                      enable_sync_button
                      && (
                        <ExpandedViewItem
                          className={ 'StartSync' }
                          icon={
                            <SyncSVG
                              fill={
                                daily_sync_available === false
                                  ? 'grey'
                                  : 'currentColor'
                              }
                            />
                          }
                          label={
                            daily_sync_available === false
                              ? <>
                                  <p style={{ color: 'grey' }}>
                                    daily sync
                                    { ' ' }
                                    <span style={{ fontSize: '64%' }}>
                                      <LockSVG fill='currentColor' height='1.4em' style={{ verticalAlign: '-3px' }} />
                                      (unlocked
                                      { ' ' }
                                      <ReactTimeAgo date={ tomorrow_date } />
                                      )
                                    </span>
                                  </p>
                                </>
                              : syncStateId === 'has-synced'
                                  ? 'get updates'
                                  : 'start sync'
                          }
                          {
                            ...(
                              daily_sync_available === true
                                ? { onClick: async_start_sync }
                                : {}
                            )
                          }
                        />
                      )
                    }
                    {
                      async_clear_sync
                      && (
                        <ExpandedViewItem
                          className={ 'StartSync' }
                          icon={ <DeleteSVG fill='currentColor' /> }
                          label='delete sync'
                          onClick={ () => {
                            const delete_sync = window.confirm( 'are you sure you want to delete all synced content?' )
                            if (delete_sync) async_clear_sync()
                          }}
                        />
                      )
                    }
                  </ExpandedViewItemGroup>
                )
              }
            </>
          }
          initialIsInline={ !module_expanded }
          onDisplayHistoryUpdate={
            ({ expanded: updated_expanded }) => {
              set_history({ id: 'PocketSyncStatus', expanded: updated_expanded })
            }
          }
        />
      : null
  )

  function getInlineContent() {
    let primary_content
    let secondary_content

    switch (syncStateId) {
      case 'currently-syncing':
        {
          if (next_sync_status?.total_synced > 0) {
            primary_content = 'currently syncing'
            secondary_content = `${ next_sync_status.total_synced } updates synced`
          }

          if (next_sync_status?.total_synced === 0) {
            primary_content = 'sync started!'
            secondary_content = 'waiting for updates'
          }
        }
        break

      case 'has-synced':
      case 'never-synced':
        {
          primary_content = syncStateId === 'has-synced'
            ? <>
                { 'last synced ' }
                <ReactTimeAgo date={ lastSyncDate } />
              </>
            : 'never synced before'

          if (next_sync_status?.status === 'queued') secondary_content = 'queued waiting to start'
          else {
            secondary_content = <>
              <span
                className={
                  `Action StartSync${
                    enable_sync_button && daily_sync_available === true
                      ? ''
                      : ' disabled'
                  }`
                }
                {
                  ...(
                    enable_sync_button && daily_sync_available === true
                      ? { onClick: () => async_start_sync?.() }
                      : {}
                  )
                }
              >
                <span className="Icon">
                  <SyncSVG />
                </span>
                <span className="Label">
                  {
                    daily_sync_available === false
                      ? <p>
                          daily sync
                          {' '}
                          <span style={{ fontSize: '.85em' }}>
                            <LockSVG fill='currentColor' height='1.4em' style={{ verticalAlign: '-3px' }} />
                            <span style={{ display: 'inline-block' }}>
                              (unlocked
                              {' '}
                              <ReactTimeAgo date={ tomorrow_date } />
                              )
                            </span>
                          </span>
                        </p>
                      : sync_status_action === SYNC_STATUS_ACTIONS.START_SYNC
                          ? 'starting sync'
                          : next_sync_status?.start_time
                            ? 'syncing ...'
                            : syncStateId === 'has-synced'
                              ? 'get updates'
                              : 'start sync'
                  }
                </span>
              </span>
            </>
          }
        }
        break

      default:
        throw new Error( `unknown sync state id "${ syncStateId }"` )
    }

    return (
      <>
        { primary_content }
        {
          secondary_content
          && <>
            { ' - ' }
            { secondary_content }
          </>
        }
      </>
    )
  }
}

export default PocketSyncStatus
